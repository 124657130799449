import SectionLayer from "../layouts/SectionsLayer/SectionLayer";
import { ContactStyled } from "./Contact.style";

function Contact() {
    return(
        <ContactStyled id="contact">
            <SectionLayer
                title="La suite ?"
                number="03."
                isRightContent={false}
            >
            <div className="contact-content">
                <h2>
                    Entrons en contact !
                </h2>
                <div className="contact-text">
                    <p>
                        <strong>
                        Ma boîte de réception est toujours ouverte. Que vous ayez une question ou que vous souhaitiez simplement dire bonjour, je vous repondrai au plus vite !
                        </strong>
                    </p>
                </div>
                <div>
                    <a href="mailto:christian.xu99@gmail.com" className="mail-button">Envoyez un message !</a>
                </div>
            </div>
            </SectionLayer>

        </ContactStyled>
    )
}

export default Contact;