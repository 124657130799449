import styled from "styled-components";
import { minWidth } from "../../config/breakpoints";

export const FooterStyled = styled.div`

    .footer{
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: smaller;
        margin: 150px 0px 50px 0px;
        a {
        margin: 10px;
        height: 2em;
        color:rgb(171,182,212);
        font-size: 1.5em;
        }    
        ${minWidth("l")} {
            margin: 300px 100px 50px 100px;
            font-size: large;
            a {
                display: none;
            }
        }
    }


`