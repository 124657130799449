import Welcome from "../../components/Welcome/Welcome";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import SideLeft from "../../components/SideLeft/SideLeft";
import About from "../../components/About/About"
import Footer from "../../components/Footer/Footer"
import Contact from "../../components/Contact/Contact";
import { HomePageStyled } from "./HomePage.style"
import Projects from "../../components/Projects/Projects";

library.add(fas, far, fab)

function HomePage() {
    return (<HomePageStyled>
            <main>
                <div className="content">
                        <div>
                            <div className="hello"><Welcome/></div>
                            <h1 className="name">
                                Je suis
                                <strong> Christian Xu ,</strong>
                            </h1>
                            <h2>Développeur Web Front-End .</h2>
                        </div>
                </div>
                <SideLeft/>
                <About/>
                <Projects/>
                <Contact/>
            </main>

            <footer>
                <Footer/>
            </footer>
        </HomePageStyled>)};

export default HomePage