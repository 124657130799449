import { useState } from 'react';
import '../src/css/index.css'
import HomePage from './pages/Homepage/HomePage';
import NavBar from './components/NavBar/NavBar';
import styled, { css } from "styled-components";


const ContainerBody = styled('div')(
  ({ openedMenu }) => css`
    ${openedMenu ? "filter: blur(6px)" : "" };
    margin: auto;
  `
)

const Overlay = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
`

function App() {
  const [activeClassName , setActiveClassName] = useState("");

  return (
    <>
      <NavBar activeClassName={activeClassName} setActiveClassName={setActiveClassName} />
      <ContainerBody openedMenu={activeClassName === "active"}>
        <HomePage />
      </ContainerBody>
      {activeClassName === "active" && 
        <Overlay
          className='overlay'
          onClick={() => {
            setActiveClassName("");
            document.querySelector("body").style.overflow = "visible"
          }} 
        />
      }
    </>
  )
}

export default App;
