import styled from "styled-components";
import { maxWidth } from "../../config/breakpoints";

export const SideLeftStyled = styled.div`

    ${maxWidth("l")} {
        display: none;
    }

    .side-left {
        display: flex;
        flex-direction: column;
        width: 40px;
        position: fixed;
        top: 10;
        bottom: 200px;
        left: 50px;
        right: auto;

        a {
        height: 2em;
        color:rgb(171,182,212);
        font-size: 1.5em;
        text-transform: uppercase;
        transition: 0.3s;
            :hover {
                transform: scale(1.2);
                color: rgb(99,255,217);
            }
        }    

        .vl {
            border-left: 1px solid rgb(171,182,212);
            height: 500px;
            position: absolute;
            left: 50%;
            margin-left: -11px;
            top: 160px;
        }
    }
`