import styled from "styled-components"

export const ContactStyled = styled.div`

    .contact-content{
        padding-top: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .contact-text{
            margin: 10px 0px 80px 0px;
        }
        .mail-button {
            color: #64ffda;
            padding: 10px 30px;
            border: 1px solid #64ffda;
            border-radius: 4px;
            text-decoration: none;
            font-size: 15px;
            :hover {
                background-color:rgba(100,255,218,0.1);
            }
        }
    }

`