import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SideLeftStyled } from "./SideLeft.style"

function SideLeft() {

    return (
        <SideLeftStyled>
            <div className="side-left">
                <a href="https://github.com/Christianxu99" target="blank"><FontAwesomeIcon icon="fa-brands fa-github" /></a>
                <a href="https://www.linkedin.com/in/christianxu/" target="blank"><FontAwesomeIcon icon="fa-brands fa-linkedin-in" /></a>
                <a href="mailto:christian.xu99@gmail.com"><FontAwesomeIcon icon="fa-solid fa-envelope" /></a>
                <div className="vl">
                    {/* vertical line */}
                </div>
            </div>
        </SideLeftStyled>
    )
}

export default SideLeft;