import styled from "styled-components";
import { maxWidth , minWidth } from "../../config/breakpoints";

export const HomePageStyled = styled.div`

    main{
        padding : 0px 300px;

        ${maxWidth('l')}{
            padding: 0px 30px;
        }
        ${minWidth('xxl')}{
            padding: 0px 285px;
        }
        ${minWidth('xxxl')}{
            padding: 0px 585px;
        }
    }
    .content {
        min-height: calc(100vh - 132px);
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .hello {
            min-height: 100px;
            color: rgb(99,255,217);
            font-size: 50px;
        }
        .name {
            font-size: 100px;
            strong {
                color: rgb(99,255,217);
            }
        }
    }

    .content-main {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }    


    ${maxWidth("l")}{
        .content {
            box-sizing: border-box;
            padding: 0px 50px;
            display: flex;
            justify-content: center;
            font-size: 10px;
            text-align: center;
            .hello {
                font-size: 30px;
            }
            .name{
                font-size:40px;
            }
        }
    }



`
