import React from "react";
import Typewriter from "typewriter-effect";

function Welcome() {
  return (
    <Typewriter
      options={{
        strings: [
          "Bonjour !",
          "Hello !",
          "お早うございます !",
          "Buenos días !",
          "Hyvää huomenta !",
          "صباح الخير !",
          "Namaste !",
          "좋은 아침 !",
          "शुभ प्रभात !",
          "Günaydin !",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 10,
      }}
    />
  );
}

export default Welcome;


