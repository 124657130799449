import SectionLayer from "../layouts/SectionsLayer/SectionLayer"
import { ProjectsStyled } from "./Projects.style"

function Projects(){

    return(
    <ProjectsStyled id="project">
        <SectionLayer
        title="Quelques-uns de mes projets"
        number="02."
        isRightContent={true}
        >
        <div className="project-content">
            <h2 className="green">ChatApp</h2>
            <div className="img-bgc">
                <a href="https://chat-app-taupe-beta.vercel.app/" target="blank"><img src="ChatApp.png" alt="ChatApp" /></a>
                <div className="content-text">
                   Application de messagerie.<br/>
                    <span>NextJS - MONGODB</span>
                </div>

            </div>
            <h2 className="green">La Maison Jungle</h2>
            <div className="img-bgc">
                <a href="https://62926747b52d86248f02e88c--splendid-salamander-357039.netlify.app/" target="blank"><img src="LMJ.png" alt="la-maison-jungle" /></a>
                <div className="content-text">
                    Mini-site de plante.<br/>
                    <span>ReactJS</span>
                </div>

            </div>
            <h2 className="green">Astronomy Picture of the Day</h2>
            <div className="img-bgc">
                <a href="https://subtle-paprenjak-e3a20f.netlify.app/" target="blank"><img src="APOD.png" alt="la-maison-jungle" /></a>
                <div className="content-text">
                    Affichage de L'APOD via l'API de la NASA.<br/>
                    <span>ReactJS</span>
                </div>
            </div>
        </div>
        </SectionLayer>
    </ProjectsStyled>
 
    )
}

export default Projects