import styled, { css } from "styled-components"
import { maxWidth } from "../../../config/breakpoints"

export const SectionLayerStyle = styled.div(({ isRightContent }) => `
    width: 100%;
    padding-top: 170px;
    margin-bottom: 300px;
    
    .align-heading {
        display: flex;
        align-items: ${isRightContent ? 'center' : 'baseline'};
        
        .green {
            
            ${isRightContent 
            ? css`
                font-size: 37px;
                margin-left: 30px;
            ` 
            :  css`
                font-size: 37px;
                margin-right: 30px;
            ` }
        }

        .numbered-heading {
            display: flex;
            width: 100%;
            font-size: 30px;
            margin:0;
            align-items: center;

            ${isRightContent 
            ? css`
                :before {
                    content: "";
                    display: block;
                    position: relative;
                    width: 10%;
                    height: 1px;
                    padding-left: 200px;
                    margin-left: auto;
                    margin-right: 30px;
                    background-color: #233554;
                }
            ` 
            :  css`
                :after {
                    content: "";
                    display: block;
                    position: relative;
                    width: 350px;
                    height: 1px;
                    margin-left:30px;
                    background-color: #233554;
                }
            ` }
        }

        ${maxWidth('l')}{
            justify-content: center;
            border-bottom: 1px solid #233554;
            
            .numbered-heading {
                display: flex;
                align-items: center;
                font-size: 25px;

                :after, :before {
                    content: inherit
                }
            }

            .green {
                font-size: 30px;
                margin-right: 20px;
                margin-left:0px;
            }
        }
    }
`)