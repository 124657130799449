import styled from "styled-components";
import { maxWidth } from '../../config/breakpoints'

export const AboutStyled = styled.div `

    .about-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top:50px;
        ul {
            display: grid;
            grid-template-columns: repeat(2, minmax(140px, 200px));
            gap: 0px 10px;
            padding: 0px;
            margin: 20px 0px 0px;
            overflow: hidden;
            list-style: none;

            li {
                color: rgb(99,255,217);
                position: relative;
                margin-bottom: 10px;
                padding-left: 30px;
                font-size: 13px;

                :before {  
                    content: "▹";
                    position: absolute;
                    top: 7px;
                    left: 0;
                    color: rgb(99,255,217);
                    font-size: 20px;
                    line-height: 12px;
                }
            }
        }

        a {
            text-decoration: none;
            transition: 400ms;
            border-bottom: 1px solid transparent;

            :hover {
                border-color: rgb(99,255,217);
            }
        }

        p {
            margin: 0px 0px 20px;
        }

        .img-bgc {
            display: flex;
            border-radius: 2%;
            background: rgba(99,255,217,1);
            max-width:400px;
            top: 40px;
            height: max-content;
            position: relative;
            margin-left:100px;

            img {
                
                opacity: 0.7;
                width: 100%;
                transition: .3s ease-in-out;
                :hover {  
                    opacity: 1;
                }
            }

            :after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                border: 2px solid rgb(99,255,217);
                border-radius: 2%;
                bottom: 20px;
                left: 20px;
                z-index: -1;
            }
        }
    }
    
    ${maxWidth('l')}{
        .about-content {
            flex-direction: column;
            align-items: flex-start;

            .img-bgc {
                width: calc(60% - 20px);
                margin: 40px auto 0;
                position: relative;
                right: 20px;
            }
        }
    }
`