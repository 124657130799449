import { SectionLayerStyle } from "./Sectionlayer.style";

function SectionLayer({children, isRightContent, title, number}) {

    return(
        <SectionLayerStyle
            isRightContent={isRightContent}
        >
            {isRightContent
            ?<div className="align-heading">
                <h2 className="numbered-heading">{title}</h2> 
                <span className="green">{number}</span>
             </div>
            : <div className="align-heading">
                <span className="green">{number}</span>
                <h2 className="numbered-heading">{title}</h2>
              </div>
            }
            <div className="section-layer-content">
                {children}
            </div>
        </SectionLayerStyle>
    )
}

export default SectionLayer;