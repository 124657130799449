import SectionLayer from "../layouts/SectionsLayer/SectionLayer";
import { AboutStyled } from "./About.style"

function About() {

    return (
        <AboutStyled id="about">
             <SectionLayer
                title="À propos de moi"
                number="01."
                isRightContent={false}
             >
                <div className="about-content" >
                    <div>
                        <p>
                            <strong>
                                    Bonjour !
                                    <br/><br/>
                                    Je m'appelle <span className="green">Christian</span>.
                                    <br/>
                                    Passionné d'informatique et des nouvelles technologies, j'ai décidé de faire une reconversion professionnelle en 2021 dans le domaine du développement web en suivant une formation type "boot camp" à <a href="https://lapasserelle.school/" target="blank" className="green">
                                        " La Passerelle "
                                    </a> situé à Paris.
                                    <br/>
                                    À l'issue de cette formation, j'ai obtenu un titre RNCP de niveau V Développement web et web mobile.
                                    <br/>
                                    Lors de mes précédentes expériences, j'ai eu l'occasion de travailler à plusieurs postes et avoir différentes responsabilités.
                                    <br/>
                                    Ces opportunités m'ont permis de développer un sens des priorités, de la hiérarchie et une cohésion d'équipe assez importante.
                                    <br/><br/>
                                    Quelques-unes de mes compétences  :
                                    <br/><br/>
                            </strong>
                        </p>        
                        <ul>
                            <li>HTML</li>
                            <li>CSS</li>
                            <li>Javascript</li>
                            <li>React JS</li>
                            <li>PHP</li>
                            <li>Symfony</li>
                        </ul>
                    </div>
                    <div className="img-bgc">
                        <img src="./me.jpg" alt="me" />
                    </div>
                    <div className="img-after">

                    </div>
                </div> 
             </SectionLayer>
        </AboutStyled>
    )
}

export default About;
