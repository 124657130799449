export const breakpoints = ({
    xxxl :3400,
    xxl : 2800,
    xl: 1400,
    l: 1024,
    m: 768,
    s: 576,
    xs: 0
});

export const maxWidth = (size) => `@media screen and (max-width: ${breakpoints[size]}px)`;
export const minWidth = (size) => `@media screen and (min-width: ${breakpoints[size]}px)`;