import styled from "styled-components";
import { minWidth } from "../../config/breakpoints";
import { theme } from "../../config/theme";

export const NavBarStyled = styled.div`

    position : sticky;
    top: 0;
    backdrop-filter: blur(5px);
    z-index: 100;

    .nav {
        align-items: center;
        .logo-desktop {
            display: none;

            img {
                width: 80px;
            }
        }

        .menu-elements {
            display: none;
            margin: 0px -20px;
            div {
                padding: 30px;
                a {
                    width: 100%;
                    text-decoration: none;
                    color : inherit;
                    margin-left: 10px;
                    :hover {
                        color: rgb(99,255,217);
                    }
                }

                .cv-button {
                    color: #64ffda;
                    background-color: transparent;
                    padding: 10px 30px;
                    border: 1px solid #64ffda;
                    border-radius: 4px;
                    line-height: 1;
                    text-decoration: none;
                    font-size: 15px;

                    :hover {
                        background-color:rgba(100,255,218,0.1);
                    }
                }
            }

            ${minWidth("l")} {
                display: flex;
                height: ${theme.desktopHeaderHeight};
            }
        }

        .active {
            display: flex;
            flex-direction: column;
            div{
                width: 200px;
                font-size: larger;
                margin-bottom: 20px;
                .cv-button{
                    padding: 20px 70px;
                    margin: auto;
                }
            }
        }

        ${minWidth("l")} {
            box-shadow: 0 10px 30px -10px rgba(2,12,27,0.7);
            display: flex;
            justify-content: space-between;
            padding: 20px 40px;

            .logo-desktop {
                display: flex;
            }
        }
    }

    .nav .active {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
        padding: 50px 10px;
        position: fixed;
        background-color: #112240;
        width: 75%;
        height: 100vh;
        right: 20px;
        top: 0;

        ${minWidth("l")}{
            display: none;
        }
    }

    .nav-mobile {
        align-items: center;
        height: auto;
        z-index: 100;
        box-shadow: 0 10px 30px -10px rgba(2,12,27,0.7);
        position: relative;
        padding: 20px 40px;
        display: flex;
        justify-content: space-between;
        
        .burger {
            font-size: 1.2em;
            z-index: 2000;
        }

        .logo img {
            width: 80px;
        }

        ${minWidth("l")} {
            display: none;

            .logo {
                display: none;
            }
        }
    }

    .close {
        position: absolute;
        font-size: 1.2em;
        top: 45px;
        right: 50px;
        z-index: 102;
    }
`;
