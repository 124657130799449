import styled from "styled-components";
import { maxWidth } from '../../config/breakpoints'

export const ProjectsStyled = styled.div`

.project-content{

    h2{
        text-align: center;
        margin-top: 200px;
    }

    .img-bgc {
        display: flex;
        border-radius: 2%;
        background: rgba(99,255,217,1);
        width: 700px;
        top: 20px;
        margin: auto;
        height: max-content;
        position: relative;


        img {
            opacity: 0.7;
            width: 100%;
            transition: .3s ease-in-out;
            :hover {  
                opacity: 1;
            }
        }
        .content-text{
            text-align: center;
            position: absolute;
            padding: 10px;
            top: 430px;
            left: 25px;
            width: 90%;
            border-radius: 2%;
            background-color: rgb(16,34,64);
        }
    }

    ${maxWidth('l')}{
        .img-bgc {
            width: 100%;
            .content-text{
            padding: 10px;
            top: 200px;
            left: 5px;
            width: 90%;
            border-radius: 2%;
            background-color: rgb(16,34,64);
        }
        }
    }
}
`